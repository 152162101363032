<template>
    <PageLayout>
        <template>
            <TabsNav :items="breadcrumbs_menu[0]['items']"/>
            <div class="d-flex w-100 justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('menu_calls') }}</h1>
                </div>
            </div>
            <div v-if="filter_show">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <v-form class="filter" @submit.prevent="getCountChart('search')">
                        <div class="filter__title mb-5">
                            {{ $t('filter_title') }}
                        </div>
                        <v-row class="py-5">
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuFrom" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateFrom" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateFrom" :error-messages="errors"
                                                          readonly v-on="on"
                                                          @click:clear="dateFrom = null"
                                                          :label="$t('date_from')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateFrom"
                                                   :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="dateMenuFrom = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuTo" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateTo" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateTo" :error-messages="errors"
                                                          :label="$t('date_to')"
                                                          readonly v-on="on"
                                                          @click:clear="dateTo = null"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateTo"
                                                   :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :max="$moment().format('YYYY-MM-DD')" :locale="lang"
                                                   @change="dateMenuTo = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider ref="auditor" rules="min:1" v-slot="{ errors, valid }">

                                    <UserSelection
                                        multiple
                                        v-model="admins"
                                        id="auditor"
                                        :label="$t('manager')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable

                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider ref="clean_talk_duration" rules="min:1" v-slot="{ errors, valid }">
                                    <SelectInput
                                        clearable
                                        item-text="name"
                                        item-value="value"
                                        hide-details
                                        hide-label
                                        :items="cleanTalkDurationItems"
                                        v-model="clean_talk_duration"
                                        name="clean_talk_duration"
                                        id="clean_talk_duration"
                                        :hideLabel="false"
                                        :label="$t('conversation_duration')"
                                    ></SelectInput>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems"
                                          :label="$t('items_per_page')"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details
                                          outlined
                                          solo
                                          flat
                                          dense
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn @click="searchFilter" :disabled="loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary" class="infinity_button">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-form>
                </ValidationObserver>
            </div>


            <v-card flat class="mt-5 pb-2">
                <v-card-text>
                    <v-row>
                        <v-col class="pt-0" cols="12">
                            <div class="d-flex">
                                <div >
                                    <v-btn text @click="chart_type = 'day'" :color="chart_type === 'day' ? 'primary' : ''">
                                        {{$t('day')}}
                                    </v-btn>
                                    <v-btn text @click="chart_type = 'month'" :color="chart_type === 'month' ? 'primary' : ''">
                                        {{$t('month')}}
                                    </v-btn>
                                </div>
                                <div class="ml-auto">
                                    <v-select
                                        v-model="m_header"
                                        :items="headers"
                                        chips
                                        label="Chips"
                                        multiple
                                        solo
                                        return-object
                                        item-text="text" item-value="value"
                                        hide-details
                                        flat
                                        dense
                                        outlined
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"

                                        @change="updateHeaders"


                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                        clearable >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip v-if="index === 0">
                                                <span>{{ item.text }}</span>
                                            </v-chip>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                  (+{{ m_header.length - 1 }} {{$t('others')}})
                                                </span>
                                        </template>
                                    </v-select>
                                </div>

                            </div>
                        </v-col>
                        <v-col class="py-0" cols="12">
                            <v-card class="d-flex flex-wrap" flat tile >
                                <template v-for="(chart, i) in charts">
                                    <v-card  v-if="chart_type === chart.chart_type"
                                             min-width="100%"
                                             max-width="100%"
                                             class="pa-0 ma-0  mx-auto text-center" elevation="3" outlined tile>
                                        <horizontal-bar-chart v-if="false" :chartData="JSON.stringify(chart.chart)" ></horizontal-bar-chart>
                                        <bar-chart v-if="chart_type === 'day'" :chartData="JSON.stringify(chart.chart)" :options="optionsChartBar"></bar-chart>
                                        <line-chart v-else :chartData="JSON.stringify(chart.chart)" ></line-chart>

                                    </v-card>
                                </template>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card flat class="mt-5">
                <v-card-text class="pt-7">
                    <v-data-table :headers="headers2" :items="listAdminItems" :loading="loading" :locale="lang"
                                  :loading-text="$t('loading_please_wait')"
                                  disable-pagination hide-default-footer
                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                  class="elevation-0"
                                  :item-class="rowClass2">
                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                            <thead>
                            <tr class="rowHeadClass Table7" >
                                <th v-for="h in headers" :key="h.value" class="table_header" :style="h.value!=='name' ? 'text-align: center !important;' : ''">
                                    <span>{{h.text}}</span>
                                </th>
                            </tr>
                            </thead>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <div :class="item.deleted ? 'red--text' : ''">
                                    <span class="font_weight_600" >
                                        {{  item.name }}
                                    </span>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <v-card flat class="mt-5">
                <v-card-text class="pt-7">
                    <v-data-table :headers="filteredHeaders" :items="listtems" :options.sync="options" :page.sync="page"
                                  :items-per-page="perPage" :server-items-length="totalLists"
                                  :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                                  @page-count="pageCount = options.pageCount = Number($event)"
                                  :loading-text="$t('loading_please_wait')"
                                  hide-default-footer

                                  :item-class="rowClass">




                        <template v-slot:item.start_time="{ item }">
                            <div :class="item.is_lost ? 'text_red' : ''">{{ $moment(item.start_time).format('DD.MM.YYYY HH:mm')  }}</div>
                        </template>
                        <template v-slot:item.finish_time="{ item }">
                            <div :class="item.is_lost ? 'text_red' : ''">{{ $moment(item.finish_time).format('DD.MM.YYYY HH:mm')  }}</div>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <div :class="item.is_lost ? 'text_red' : ''">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm')  }}</div>
                        </template>
                        <template v-slot:item.clean_talk_duration="{ item }">
                            <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.clean_talk_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                        </template>

                        <template v-slot:item.wait_duration="{ item }">
                            <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.wait_duration, 'seconds').asMilliseconds()).format('mm:ss')  }} - {{item.wait_duration }}</div>
                        </template>
                        <template v-slot:item.lost_call_processing_duration="{ item }">
                            <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.lost_call_processing_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                        </template>
                        <template v-slot:item.talk_duration="{ item }">
                            <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.talk_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                        </template>
                        <template v-slot:item.total_duration="{ item }">
                            <div :class="item.is_lost ? 'text_red' : ''">{{ $moment.utc($moment.duration(item.total_duration, 'seconds').asMilliseconds()).format('mm:ss')  }}</div>
                        </template>


                        <template v-slot:item.contact_phone_number="{ item }">
                            <div class="phone" :class="item.is_lost ? 'text_red' : ''">
                                {{ formatPhoneNumber(item.contact_phone_number) }}
                            </div>
                        </template>
                        <template v-slot:item.virtual_phone_number="{ item }">
                            <div class="phone" :class="item.is_lost ? 'text_red' : ''">
                                {{ formatPhoneNumber(item.virtual_phone_number) }}
                            </div>
                        </template>
                        <template v-slot:item.url="{ item }">
                            <div>
                                <a v-if="item.url" :href="item.url" target="_blank">
                                    <v-icon >
                                        mdi-play-circle-outline
                                    </v-icon>
                                </a>
                            </div>
                        </template>
                        <template v-slot:item.direction="{ item }">
                            <div>
                                <v-icon v-if="item.direction === 'in'" color="primary">
                                    mdi-phone-incoming
                                </v-icon>
                                <v-icon v-else color="green">
                                    mdi-phone-outgoing
                                </v-icon>
                            </div>
                        </template>


                        <template v-slot:item.admin.name="{ item }" >
                            <div :class="item.is_lost ? 'text_red' : ''">
                                {{ item.admin.name }}
                            </div>
                        </template>

                        <template v-slot:item.uved_name="{ item }" >
                            <div :class="item.is_lost ? 'text_red' : ''">
                                <router-link :to="{'name': 'uved.show', 'params': {'id': item.uved_uuid}}">
                                    {{ item.uved_name }}
                                </router-link>
                            </div>
                        </template>


                        <template v-slot:item.is_lost="{ item }" >
                            <div :class="item.is_lost ? 'text_red' : ''">{{ item.is_lost ? $t('yes') : $t('no')}}</div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pt-0 justify-end">
                    <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table"
                                  :length="pageCount" :disabled="loading"></v-pagination>
                </v-card-actions>
            </v-card>

        </template>
        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5"  color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>
            <report-menu @filter_show="handleFilterShow"></report-menu>
        </template>


    </PageLayout>
</template>
<script>



import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import BarChart from "../../plugins/charts/BarChart";
import LineChart from "../../plugins/charts/LineChart";
import ReportMenu from "../../components/ReportMenu";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import UserSelection from "@/components/Form/UserSelection.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";


export default {
    name: "CallReports",
    components: {
        ReportMenu,
        BarChart,
        LineChart,
        ValidationProvider,
        ValidationObserver,
        UserSelection,
        PageLayout,
        SelectInput,
        TabsNav

    },
    data() {
        return {
            loading:false,
            charts: [],
            optionsChartLine: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }]
                },
            },
            optionsChartBar: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }]
                },
            },

            filter_show: false,
            email_search: null,
            chart_type: 'month',
            listtems: [],
            sortBy: "start_time",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalLists: 0,
            options: {},
            headers: [
                {
                    text: this.$t('type'),
                    align: "left",
                    sortable: true,
                    value: "direction",
                    hide: false
                },
                {
                    text: this.$t('manager'),
                    align: "left",
                    sortable: true,
                    value: "admin.name",
                    hide: false
                },
                {
                    text: this.$t('start_call'),
                    align: "left",
                    sortable: true,
                    value: "start_time",
                    hide: false
                },
                {
                    text: this.$t('finish_call'),
                    align: "left",
                    sortable: false,
                    value: "finish_time",
                    hide: true
                },
                {
                    text: this.$t('clean_talk_duration'),
                    align: "left",
                    sortable: true,
                    value: "clean_talk_duration",
                    hide: false
                },
                {
                    text: this.$t('contact_phone_number'),
                    align: "left",
                    sortable: true,
                    value: "contact_phone_number",
                    hide: false
                },
                {
                    text: this.$t('record'),
                    align: "left",
                    sortable: true,
                    value: "url",
                    hide: false
                },

                {
                    text: this.$t('is_lost'),
                    align: "left",
                    sortable: true,
                    value: "is_lost",
                    hide: true
                },

                {
                    text: this.$t('uved_name'),
                    align: "left",
                    sortable: true,
                    value: "uved_name",
                    hide: true
                },

                {
                    text: this.$t('virtual_phone_number'),
                    align: "left",
                    sortable: true,
                    value: "virtual_phone_number",
                    hide: true
                },
                {
                    text: this.$t('wait_duration'),
                    align: "left",
                    sortable: true,
                    value: "wait_duration",
                    hide: true
                },
                {
                    text: this.$t('lost_call_processing_duration'),
                    align: "left",
                    sortable: true,
                    value: "lost_call_processing_duration",
                    hide: true
                },

                {
                    text: this.$t('talk_duration'),
                    align: "left",
                    sortable: true,
                    value: "talk_duration",
                    hide: true
                },
                {
                    text: this.$t('total_duration'),
                    align: "left",
                    sortable: true,
                    value: "total_duration",
                    hide: true
                },
                {
                    text: this.$t('date_created'),
                    align: "left",
                    sortable: true,
                    value: "created_at",
                    hide: true
                },

            ],
            m_header: [],
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,
            listAdminItems: [],
            headers2:[
                {
                    text: this.$t('manager'),
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t('quantity_short'),
                    align: "center",
                    sortable: true,
                    value: "count",
                },
                {
                    text: this.$t('successful_calls'),
                    align: "center",
                    sortable: true,
                    value: "successful_calls",
                },
                {
                    text: this.$t('unsuccessful_calls'),
                    align: "center",
                    sortable: true,
                    value: "unsuccessful_calls",
                },
                {
                    text: this.$t('daily_plan'),
                    align: "center",
                    sortable: true,
                    value: "daily",
                },
                {
                    text: this.$t('weekly_plan'),
                    align: "center",
                    sortable: true,
                    value: "weekly",
                },
                {
                    text: this.$t('monthly_plan'),
                    align: "center",
                    sortable: true,
                    value: "monthly",
                },
            ],
            admins: null,
            clean_talk_duration: null,
            cleanTalkDurationItems: [
                {'name': '15 сек.', 'value': 15},
                {'name': '30 сек.', 'value': 30},
                {'name': '45 сек.', 'value': 45},
                {'name': 1, 'value': 60},
                {'name': 2, 'value': 120},
                {'name': 3, 'value': 180},
                {'name': 4, 'value': 240},
                {'name': 5, 'value': 300},
                {'name': 6, 'value': 360},
                {'name': 7, 'value': 420},
                {'name': 8, 'value': 480},
                {'name': 9, 'value': 540},
                {'name': 10, 'value': 600},
            ],
            breadcrumbs_menu:[
                {
                    items: [
                        {
                            title: 'menu_dashboard',
                            route: {name: "dashboard"},
                            permissions: ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
                            notification_group: 'dashboard',
                            icon: "icon-chart",
                        },
                        {
                            title: 'statistics',
                            route: {name: "report_call"},
                            permissions: ['administrator', 'director'],
                            notification_group: 'report_call',
                            icon: 'icon-analytics-graph',
                        }
                    ],
                },
            ]
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    watch: {
        options: {
            handler() {
                this.getCountList()
            },
            deep: false
        }
    },
    mounted() {
        this.dateFrom = this.$moment().startOf('month').format('YYYY-MM-DD');
        this.dateTo = this.$moment().format('YYYY-MM-DD');
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getCountChart()
        this.getCountListAdmin()
        for (let i = 0; i < this.headers.length; i++) {
            if(this.headers[i].hide === false){
                this.m_header.push(this.headers[i]);
            }
        }


    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClassAuto' : 'rowMobiClass';
        },
        rowClass2(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table7' : 'rowMobiClass';
        },
        updateHeaders(){
            for (let i = 0; i < this.headers.length; i++) {
                let yes = 0;
                for (let x = 0; x < this.m_header.length; x++) {
                    if(this.headers[i].value === this.m_header[x].value){
                        yes = 1;
                    }
                }
                this.headers[i].hide = yes !== 1;
            }
        },
        searchFilter(){
            this.getCountChart();
            this.getCountListAdmin();
            this.getCountList();
        },
        getCountChart() {
            var formData = new FormData()
            if (this.dateFrom) {
                formData.append(`dateFrom`, this.dateFrom)
            }
            if (this.dateTo) {
                formData.append(`dateTo`, this.dateTo)
            }


            if (this.admins && this.admins.length > 0) {
                for (let i in this.admins) {
                    if (this.admins[i].id !== undefined && this.admins[i].id > 0) {
                        formData.append(`admins[${i}]`, this.admins[i].id)
                    }
                }
            }

            if (this.clean_talk_duration) {
                if (this.clean_talk_duration.id) {
                    formData.append('clean_talk_duration', this.clean_talk_duration.id)
                } else {
                    formData.append('clean_talk_duration', this.clean_talk_duration)
                }
            }

            this.$http
                .post('admin/reports/call_chart',  formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    this.charts = res.body.data
                })
                .catch(err => {
                    this.charts = [];
                    this.$toastr.error(this.$t('failed_to_get_list_charts'))
                })
        },
        async getCountList() {
            this.loading = true
            var formData = new FormData()
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                formData.append(`sortBy`, sortBy[0])
            }
            if (sortDesc[0] !== undefined) {
                formData.append(`sortDir`, sortDesc[0] ? 'asc' : 'desc')
            }
            if (page !== undefined) {
                formData.append(`page`, page)
            }
            if (itemsPerPage !== undefined) {
                formData.append(`perPage`, itemsPerPage)
            }

            if (this.dateFrom) {
                formData.append(`dateFrom`, this.dateFrom)
            }
            if (this.dateTo) {
                formData.append(`dateTo`, this.dateTo)
            }

            if (this.clean_talk_duration) {
                if (this.clean_talk_duration.id) {
                    formData.append('clean_talk_duration', this.clean_talk_duration.id)
                } else {
                    formData.append('clean_talk_duration', this.clean_talk_duration)
                }
            }

            if (this.admins && this.admins.length > 0) {
                for (let i in this.admins) {
                    if (this.admins[i].id !== undefined && this.admins[i].id > 0) {
                        formData.append(`admins[${i}]`, this.admins[i].id)
                    }
                }
            }


            await this.$http
                .post('admin/reports/call',  formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    this.listtems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalLists = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.listtems = []
                    this.totalLists = 0
                    this.$toastr.error(this.$t('failed_to_get_list_administrators'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getCountListAdmin() {
            this.loading = true


            var formData = new FormData()

            if (this.dateFrom) {
                formData.append(`dateFrom`, this.dateFrom)
            }
            if (this.dateTo) {
                formData.append(`dateTo`, this.dateTo)
            }

            if (this.admins && this.admins.length > 0) {
                for (let i in this.admins) {
                    if (this.admins[i].id !== undefined && this.admins[i].id > 0) {
                        formData.append(`admins[${i}]`, this.admins[i].id)
                    }
                }
            }
            if (this.clean_talk_duration) {
                if (this.clean_talk_duration.id) {
                    formData.append('clean_talk_duration', this.clean_talk_duration.id)
                } else {
                    formData.append('clean_talk_duration', this.clean_talk_duration)
                }
            }
            await this.$http
                .post('admin/reports/call_admin',  formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    this.listAdminItems = res.body.data
                })
                .catch(err => {
                    this.listAdminItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_administrators'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>

<style scoped lang="scss">
.filter {
    background-color: #fff;
    padding: $grid-gutter;
    border-radius: $border-radius-root;
}

.filter__title {
    font-size: 20px;
}
</style>

